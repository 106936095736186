import { post } from "@/utils/axios";

export const mmpiLbIdRange = [19, 22, 78, 124, 174, 175];
export const nuclearLbIdRange = [114];

export const reportMode = {
  admin: {
    brief: "admin_brief",
    detailed: "admin_detailed",
    hosbrief: "admin_hos_brief",
    hosdetailed: "admin_hos_detailed"
  },
  person: {
    brief: "person_brief",
    detailed: "person_detailed",
    hosbrief: "person_hos_brief",
    hosdetailed: "person_hos_detailed"
  }
};

export function fetchReportHtml(
  caseGuid,
  reportModeName,
  isReportBodyFromDb = true
) {
  return generateReportHtml(caseGuid, {
    reportModeName,
    isReportBodyFromDb
  });
}

async function generateReportHtml(
  caseGuid,
  { reportModeName, isReportBodyFromDb }
) {
  let response = await post(`/report/GenerateReportHtml/${caseGuid}`, {
    reportModeName,
    isReportBodyFromDb
  });
  return {
    reportTitle: response.reportTitle,
    reportHtml: response.reportHtml,
    reportBody: response.reportBody,
    reportFileName: response.reportFileName
  };
}

export async function generateGroupReportHtml(caseObjList) {
  let response = await post(
    "/groupreport/GenerateGroupReportHtml",
    caseObjList
  );
  return {
    reportTitle: response.reportTitle,
    reportHtml: response.reportHtml,
    reportFileName: response.reportFileName
  };
}

export async function generateGroupReportRawData(caseObjList) {
  let response = await post(
    "/groupreport/GenerateGroupReportRawData",
    caseObjList
  );
  return response.reportRawData;
}

export async function fetchTestAnswerDetailsHtml(caseGuid) {
  let response = await post("/reportanalyse/TestAnswerDetailsHtml", {
    guid: caseGuid
  });
  return {
    title: response.displayTitle,
    html: response.html
  };
}

export async function updateReportBody(
  caseGuid,
  { reportBodyLines, reportModeName }
) {
  await post(`/report/UpdateReportBody/${caseGuid}`, {
    reportBody: reportBodyLines,
    reportModeName
  });
}

export async function updateReportSign(caseGuid, { reporterName }) {
  await post(`/report/UpdateReportReporter/${caseGuid}`, {
    reporterName
  });
}

export async function saveReportToPdf(caseGuid, reportModeName) {
  let response = await post("/report/ExportReportToPdf", {
    caseGuid,
    reportModeName
  });
  return response.value;
}

export async function saveAnswerDetailsToPdf(caseGuid) {
  let response = await post("/reportanalyse/ExportTestAnswerDetailsToPdf", {
    guid: caseGuid
  });
  return response.value;
}

export async function bulkSaveReportToZip(reportExportList) {
  let response = await post("/report/BulkExportReportToPdf", reportExportList);
  return response.value;
}

export async function bulkSaveReportToSinglePdf(reportExportList) {
  let response = await post(
    "/report/BulkExportReportToSinglePdf",
    reportExportList
  );
  return response.value;
}

export async function saveGroupReportToPdf(caseObjList) {
  let response = await post("/groupreport/ExportGroupReportToPdf", caseObjList);
  return response.value;
}
